var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":"Commissielijsten"}}),_c('div',{staticClass:"tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto"},[_c('FormulateForm',{staticClass:"tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg",attrs:{"name":"searchCommissionLists"},on:{"submit":_vm.searchCommissionLists},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4"},[_c('FormulateInput',{attrs:{"type":"date","name":"starts_at","label":"Startdatum","placeholder":"YYYY-MM-DD","validation":"optional|date:YYYY-MM-DD","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"date","name":"ends_at","label":"Einddatum","placeholder":"YYYY-MM-DD","validation":"optional|date:YYYY-MM-DD","outer-class":"tw-m-0"}})],1),(_vm.isStaff)?[_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"office","name":"office_id","label":"Kantoren","placeholder":"Selecteer een kantoor","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","params":{ include_out_of_service: 1 },"name":"collaborator_id","label":"Medewerker","placeholder":"Selecteer een medewerker","outer-class":"tw-m-0"}})],1),_c('FormulateInput',{attrs:{"type":"select","name":"status","label":"Status","value":"","options":_vm.statusOptions}})]:_vm._e(),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-search'
          ]}),_vm._v(" Zoeken ")])]}}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('DataTable',_vm._b({attrs:{"loading":_vm.loading,"headers":_vm.headers,"paginate":true},on:{"changePage":_vm.fetchCommissionLists},scopedSlots:_vm._u([{key:"item-id",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'CommissionList', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item-collaborator",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.collaborator.first_name)+" "+_vm._s(item.collaborator.last_name)+" ")]}},{key:"item-total_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item.total_amount))+" ")]}},{key:"item-end_date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateForLocale(item.ends_at))+" ")]}},{key:"item-status",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusOptions[item.status])+" ")]}}])},'DataTable',_vm.commissionLists,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }