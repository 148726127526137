<template>
  <div>
    <PageHeader title="Commissielijsten" />
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <FormulateForm
        #default="{ isLoading }"
        v-model="query"
        name="searchCommissionLists"
        @submit="searchCommissionLists"
        class="tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg"
      >
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
          <FormulateInput
            type="date"
            name="starts_at"
            label="Startdatum"
            placeholder="YYYY-MM-DD"
            validation="optional|date:YYYY-MM-DD"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="date"
            name="ends_at"
            label="Einddatum"
            placeholder="YYYY-MM-DD"
            validation="optional|date:YYYY-MM-DD"
            outer-class="tw-m-0"
          />
        </div>
        <template v-if="isStaff">
          <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
            <FormulateInput
              type="autocomplete"
              auto-complete-type="office"
              name="office_id"
              label="Kantoren"
              placeholder="Selecteer een kantoor"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="autocomplete"
              auto-complete-type="collaborator"
              :params="{ include_out_of_service: 1 }"
              name="collaborator_id"
              label="Medewerker"
              placeholder="Selecteer een medewerker"
              outer-class="tw-m-0"
            />
          </div>
          <FormulateInput
            type="select"
            name="status"
            label="Status"
            value=""
            :options="statusOptions"
          />
        </template>
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-search'
            ]"
          />
          Zoeken
        </FormulateInput>
      </FormulateForm>
      <DataTable
        :loading="loading"
        :headers="headers"
        v-bind="commissionLists"
        :paginate="true"
        @changePage="fetchCommissionLists"
      >
        <template #item-id="{ item }">
          <router-link :to="{ name: 'CommissionList', params: { id: item.id } }">
            {{ item.id }}
          </router-link>
        </template>
        <template #item-collaborator="{ item }">
          {{ item.collaborator.first_name }} {{ item.collaborator.last_name }}
        </template>
        <template #item-total_amount="{ item }">
          {{ currency(item.total_amount) }}
        </template>
        <template #item-end_date="{ item }">
          {{ formatDateForLocale(item.ends_at) }}
        </template>
        <template #item-status="{ item }">
          {{ statusOptions[item.status] }}
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable'

import { mapState, mapGetters } from 'vuex'
import { getCommissionLists } from '@/services/commission'
import { errorModal } from '@/modalMessages'
import { currency, formatDateForLocale } from '@/utils/helpers'

export default {
  name: 'CommissionLists',
  components: {
    PageHeader,
    DataTable
  },
  data () {
    return {
      loading: false,
      query: {},
      // The commissionLists includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      commissionLists: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('account', ['collaborator']),

    isStaff () {
      return this.user.is_staff
    },
    headers () {
      const headers = [
        { text: 'Referentie', value: 'id' },
        { text: 'Medewerker', value: 'collaborator' },
        { text: 'Totaalbedrag', value: 'total_amount' }
      ]
      if (this.isStaff) {
        headers.push(
          { text: 'Status', value: 'status' },
          { text: 'Publicatiedatum', value: 'end_date' }
        )
      }
      return headers
    },
    statusOptions () {
      return {
        '': 'Alles',
        1: 'Pending',
        2: 'Published'
      }
    }
  },
  created () {
    this.fetchCommissionLists()
  },
  methods: {
    currency,
    formatDateForLocale,
    async fetchCommissionLists (payload) {
      try {
        this.loading = true
        const response = await getCommissionLists(payload)
        this.commissionLists = response.data
        return response
      } catch (error) {
        errorModal('Fout bij het laden van commissielijsten, probeer het opnieuw.')
        throw error
      } finally {
        this.loading = false
      }
    },
    async searchCommissionLists (data = {}) {
      try {
        const params = { ...data }
        if (params.office_id) params.office_id = params.office_id.id
        if (params.collaborator_id) params.collaborator_id = params.collaborator_id.id

        // We need to append 'T' fields because API doesn't support TZ aware ISO dates :(
        if (params.starts_at) params.starts_at = params.starts_at + 'T00:00:00'
        if (params.ends_at) params.ends_at = params.ends_at + 'T23:59:59'

        return await this.fetchCommissionLists({ params })
      } catch (error) {
        this.$formulate.handle(error, 'searchCommissionLists')
      }
    }
  }
}
</script>
